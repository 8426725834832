/* index.css */

body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  font-weight: normal;
  background-color: #000000;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: white;
}

h1 {
  font-size: 40px;
}

h2 {
  font-size: 34px;
}

h3 {
  font-size: 28px;
}

h4 {
  font-size: 24px;
}

h5 {
  font-size: 18px;
}

label {
  font-size: 14px;
}
